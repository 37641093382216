$(document).ready(function() {
  var urldev = 'http://192.168.1.33:8080/UPMB/contact/process-form.php';
  var urlprod = './contact/process-form.php';

  var $objform = $(document).find('#contactForm');
  if ($objform.length != 0) {
    try {
      jQuery('#contactForm').validate({
        submitHandler: function() {
          var postvalues = jQuery('#contactForm').serialize();
          //alert(postvalues);
          jQuery.ajax({
            type: 'POST',
            url: urlprod,
            data: postvalues,
            success: function(response) {
              console.log(response);
              jQuery('#status')
                .html(response)
                .show('normal');
              jQuery('#nome, #empresa, #email, #celular, #mensagem').val('');
            }
          });
          return false;
        },
        focusInvalid: true,
        focusCleanup: false,
        //errorLabelContainer: jQuery('#registerErrors'),
        rules: {
          nome: { required: true },
          empresa: { required: true },
          email: {
            required: true,
            minlength: 6,
            maxlength: 50,
            email: true
          },
          celular: { required: true },
          mensagem: { required: true, minlength: 6 }
        },

        messages: {
          nome: { required: 'Este campo é obrigatório' },
          empresa: { required: 'Este campo é obrigatório' },
          email: {
            required: 'Este campo é obrigatório',
            email: 'Por favor forneça um email válido'
          },
          celular: { required: 'Este campo é obrigatório' },
          mensagem: { required: 'Este campo é obrigatório' }
        },

        errorPlacement: function(error, element) {
          error.insertBefore(element);
          jQuery('<span class="errorarr"></span>').insertBefore(element);
        },
        invalidHandler: function() {
          //jQuery("body").animate({ scrollTop: 0 }, "slow");
        }
      });
    } catch (err) {
      alert(
        'Something went wrong, please check the settings in contact/contact.php file.'
      );
    }
  }

  /* Newsletter */

  var n_urldev = 'http://192.168.1.33:8080/UPMB/contact/process-newsletter.php';
  var n_urlprod = './contact/process-newsletter.php';

  var $objNews = $(document).find('#newsForm');
  if ($objNews.length != 0) {
    try {
      jQuery('#newsForm').validate({
        submitHandler: function() {
          var postvalues = jQuery('#newsForm').serialize();
          //alert(postvalues);
          jQuery.ajax({
            type: 'POST',
            url: n_urlprod,
            data: postvalues,
            success: function(response) {
              console.log(response);
              jQuery('#status')
                .html(response)
                .show('normal');
              jQuery('#nome, #email').val('');
            }
          });
          return false;
        },
        focusInvalid: true,
        focusCleanup: false,
        //errorLabelContainer: jQuery('#registerErrors'),
        rules: {
          nome: { required: true },
          email: {
            required: true,
            minlength: 6,
            maxlength: 50,
            email: true
          }
        },
        messages: {
          nome: { required: 'Este campo é obrigatório' },
          email: {
            required: 'Este campo é obrigatório',
            email: 'Por favor forneça um email válido'
          }
        },
        errorPlacement: function(error, element) {
          error.insertBefore(element);
          jQuery('<span class="errorarr"></span>').insertBefore(element);
        },
        invalidHandler: function() {
          //jQuery("body").animate({ scrollTop: 0 }, "slow");
        }
      });
    } catch (err) {
      alert(
        'Something went wrong, please check the settings in contact/contact.php file.'
      );
    }
  }
});



$(document).ready(function(){
  $('.testimo').owlCarousel({
    items: 1,
    loop: true,
    margin: 10,
    nav: false,
    dots: true,
    autoplay: true,
    autoplayTimeout: 30800
  });
});


/* Open modal */
$('.fotos a').click(function(e) {
  $('#modalImages img').attr('src', $(this).attr('data-img-url'));
});

$(document).on('click', '[data-toggle="lightbox"]', function(event) {
  event.preventDefault();
  $(this).ekkoLightbox();
});

/* --------------------------------------------------------
 * = Google search
 * -------------------------------------------------------*/
/*
mfa = 012733627147065495826:daik2pfvicg
upm = 012733627147065495826:f1qdvn9lpuy
*/
var config = {
  cx: '012733627147065495826:f1qdvn9lpuy',
  key: 'AIzaSyBpSxyks0ZSOkYVdGiSkHgPffLlhuAiRuk',
  query: 'unyca',
  callback: 'hndlr'
};

$('#formSearch').on('submit', function(event) {
  event.preventDefault();
  if ($('#search').val() != '') {
    setQuery($('#search').val());
    g_CallRestService(g_search());
    $('#search').val('');
  }
});

var setQuery = function(value) {
  config.query = value;
};

var g_search = function() {
  var url =
    'https://www.googleapis.com/customsearch/v1?key=' +
    config.key +
    '&cx=' +
    config.cx +
    '&q=' +
    config.query +
    '&callback=' +
    config.callback;
  return url;
};

var g_CallRestService = function(request) {
  var $spt = $(document).find('#gS');
  if ($spt.length != 0) {
    $spt.remove();
  }
  var script = document.createElement('script');
  script.setAttribute('type', 'text/javascript');
  script.setAttribute('src', request);
  script.setAttribute('id', 'gS');
  document.body.appendChild(script);
};

var hndlr = function(response) {
  $('#content').html('');
  document.getElementById('content').innerHTML +=
    '<h1>"' +
    response.queries.request[0].searchTerms +
    '"</h1>' +
    '<p>Total de resultados: ' +
    response.searchInformation.totalResults +
    '</p>';

  if (response.searchInformation.totalResults > 0) {
    for (var i = 0; i < response.items.length; i++) {
      var item = response.items[i];
      // in production code, item.htmlTitle should have the HTML entities escaped.
      document.getElementById('content').innerHTML +=
        '<br>' + '<a href="' + item.link + '">' + item.htmlTitle + '</a>';
    }
  }

  $('#modalSearch').modal('show');
};
